(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*jslint node: true, browser: true */
'use strict';

var formIdPrefix = 'mktoForm_';
var cssPrefix = '.mkto';

var removeAll = function (array) {
    var i;
    for (i = 0; i < array.length; i += 1) {
        array[i].parentNode.removeChild(array[i]);
    }
    return array;
};

var removeClassAll = function (array, className) {
    var i;
    for (i = 0; i < array.length; i += 1) {
        array[i].classList.remove(className);
    }
    return array;
};

var unwrap = function (wrapper) {
    while (wrapper.firstChild) {
        wrapper.parentNode.insertBefore(wrapper.firstChild, wrapper);
    }
    wrapper.parentNode.removeChild(wrapper);
    return wrapper;
};
var unwrapAll = function (wrappers, removeEmpty) {
    var i;
    for (i = 0; i < wrappers.length; i += 1) {
        if (wrappers[i].firstChild || removeEmpty) {
            unwrap(wrappers[i]);
        }
    }
    return wrappers;
};

var moveAllToBottom = function (elements) {
    var i;
    for (i = 0; i < elements.length; i += 1) {
        elements[i].parentNode.appendChild(elements[i]);
    }
    return elements;
};

var isStylesheetMarketo = function (stylesheet) {
    var i, rule;

    for (i = 0; i < stylesheet.cssRules.length; i += 1) {
        rule = stylesheet.cssRules[i];

        if (rule.cssText.indexOf(cssPrefix) !== -1) {
            return true;
        }
    }

    return false;
};

var marketoFresh = function (form) {
    var i,
        stylesheets = document.styleSheets,
        stylesheet,
        formElement = document.getElementById(formIdPrefix + form.getId()),
        formChildren = formElement.getElementsByTagName('*');


    formElement.removeAttribute('style');

    for (i = 0; i < formChildren.length; i += 1) {
        formChildren[i].removeAttribute('style');
    }

    formElement.className = '';

    removeAll(formElement.querySelectorAll('.mktoClear'));
    removeAll(formElement.querySelectorAll('.mktoGutter'));
    removeAll(formElement.querySelectorAll('.mktoOffset'));
    removeAll(formElement.querySelectorAll('.mktoAsterix'));
    removeAll(formElement.querySelectorAll('style'));
    removeAll(formElement.querySelectorAll('label:empty'));
    //removeAll(formElement.querySelectorAll('label'));
    removeAll(formElement.querySelectorAll('.mktoPlaceholder:empty'));
    removeAll(formElement.querySelectorAll('.mktoFormRow:empty'));

    removeClassAll(formElement.querySelectorAll('.mktoHasWidth'), 'mktoHasWidth');
    removeClassAll(formElement.querySelectorAll('.mktoFormCol'), 'mktoFormCol');

    unwrapAll(formElement.querySelectorAll('.mktoCheckboxList'));
    unwrapAll(formElement.querySelectorAll('.mktoFormRow'));
    unwrapAll(formElement.querySelectorAll('.mktoButtonRow'));
    //unwrapAll(formElement.querySelectorAll('.mktoFieldDescriptor'));
    unwrapAll(formElement.querySelectorAll('.mktoButtonWrap'));
    unwrapAll(formElement.querySelectorAll('.mktoFieldWrap'));

    unwrapAll(
        [].slice.call(formElement.querySelectorAll('.mktoFormRow input[type="hidden"]'))
            .map(function (current) {
                return current.parentNode;
            })
    );

    moveAllToBottom(formElement.querySelectorAll('input[type="hidden"]'));

    for (i = stylesheets.length - 1; i >= 0; i -= 1) {
        stylesheet = stylesheets[i];

        if (stylesheet.href && stylesheet.href.match(/\.marketo\.com/)) {
            stylesheet.disabled = true;
        }

        if (!stylesheet.href && isStylesheetMarketo(stylesheet)) {
            stylesheet.disabled = true;
        }
    }
};


/**
 * Pass a Marketo form to clean it.
 * Pass a callback to receive a function that will clean a form, and then call your callback.
 * Can be used directly in MktoForms2.loadForm as the fourth parameter.
 * @callback formOrCallback Called after marketoFresh has finished.
 * @param   {(Object|function)} formOrCallback
 * @returns {(undefined|function} If a callback was supplied, a function will be returned that when called will clean a passed form, then call the callback.
 */
module.exports = function (formOrCallback) {
    if (typeof formOrCallback === 'function') {
        return function (form) {
            marketoFresh(form);
            formOrCallback(form);
        };
    }

    return marketoFresh(formOrCallback);
};

},{}],2:[function(require,module,exports){
'use strict';
// import Cookies from 'js-cookie';

var formWrap = document.querySelector('.formWrap');
if (formWrap === null) {

} else {
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var urlScore = url.searchParams.get("score");

    var scored = getParameterByName('score');


   MktoForms2.loadForm("//app-abj.marketo.com", "309-RHV-619", 8255, function(form) {

        //Add an onSuccess handler
        form.onSuccess(function(values, followUpUrl) {
            // IT PAGE
            gtag('event', 'Submit Form', {'event_label': 'Gated IT','event_category': 'Form'});
            setTimeout(function(){
                window.location.href = 'thank_IT-en-GB.html?' + 'score=' + scored;
            }, 300);


            // Return false to prevent the submission handler continuing with its own processing
            return false;
        });
    });

    MktoForms2.loadForm("//app-abj.marketo.com", "309-RHV-619", 8256, function(form) {

        //Add an onSuccess handler
        form.onSuccess(function(values, followUpUrl) {
            // NON IT PAGE
            gtag('event', 'Submit Form', {'event_label': 'Gated Non-IT', 'event_category': 'Form' });

            setTimeout(function(){
                window.location.href = 'thank_nonIT-en-GB.html?' + 'score=' + scored;
            }, 300);

            // Return false to prevent the submission handler continuing with its own processing
            return false;
        });
    });
}





$(document).ready(function(){

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var source = url.searchParams.get("utm_source");
    var utmmedium = url.searchParams.get("utm_medium");
    var utmcampaign = url.searchParams.get("utm_campaign");
    var utmcontent = url.searchParams.get("utm_content");


    if ($('.LPtestLink').length == 1) {

        $('.LPtestLink').on('click', function(){
            window.location.href = 'testPage-en-GB.html?' + 'utm_source=' + source + '&utm_medium=' + utmmedium + '&utm_campaign=' + utmcampaign + '&utm_content=' + utmcontent;
            gtag('event', 'Click Button', {'event_label': 'Take the Test', 'event_category': 'Click'});
        })
    }


    $('.slider').slick({
             dots: false,
             infinite: false,
             speed: 300,
             slidesToShow: 1,
             slidesToScroll: 1,
             adaptiveHeight: false,
             arrows: false,
             draggable: false,
             touchMove: false,
             swipe: false,
    });

    var headerHeight = $('.headerQuestions').height();
    var winHeight = $(window).height();


    var slickslistHeight = $('.slick-list').height();
    $('.slick-slide').height(slickslistHeight+200);
    console.log(slickslistHeight);

    function answerOverlay(){
        var headerHeight = $('.headerQuestions').height();
        var winHeight = $('section.slide1').height();
        var overlayHeight = winHeight - headerHeight;
        console.log('header: '+headerHeight);

        if ($('.insightsOverlay').hasClass('active')) {
//            $('.insightsOverlay.active').height(overlayHeight);
        } else {
//           $('.insightsOverlay').height(60);
        }
    }


    // getting answers
    $('.answers label input').each(function(){
        $(this).on('click', function(){

            var dataValues = [];
            $(".answers label").find("input:checked").each(function(){
                dataValues.push($(this).attr('data-value'));
            });
            // Cookies.set('foo', 'bar')
            var answerString = dataValues.join( "" );
            console.log(answerString)
            $('input[name="results"]').val(answerString);
            $(this).parent().parent().parent().parent().children('.error').removeClass('active');
            $(this).parent().parent().parent().parent().children().children().children().children('input').removeClass('error');
            $(this).removeClass('error');
        });
    });



    if ($('.formWrap').length == 1) {
        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("answers");
        var d = url.searchParams.get("score");

        var answersUrl = getParameterByName('answers');
        var scored = getParameterByName('score');

        var answerchange1 = answersUrl.replace(",%20", "");
        var answerInject = answerchange1.replace("%20", " ");

        function formInject(){
           console.log(answerInject);
           $('input[name="personNotesNewNote"]').attr('value', answerInject);
        }


        setTimeout(function(){
            formInject();
        }, 900);
        console.log(scored);

        $('.show-results').on('click', function(){
            gtag('event', 'Skip Form', {'event_label': 'Gated Non-IT','event_category': 'Form'  });

            setTimeout(function(){
                window.location.href = 'thank_nonIT-en-GB.html?' + 'score=' + scored;
            }, 300);

        });
    } else {
        // DO NOTHING
    }

    if ($('#hackwise .socials').length == 1) {

        $('#hackwise .socials .facebook').on('click', function(){
            gtag('event', 'Pre Quiz Share', {'event_label': 'Facebook','event_category': 'Social Share'});
            setTimeout(function(){
                window.location = 'landing_facebook-en-GB.html';
            }, 300);


        });

        $('#hackwise .socials .twitter').on('click', function(){
            gtag('event', 'Pre Quiz Share', {'event_label': 'Twitter','event_category': 'Social Share'});
            setTimeout(function(){
                window.location = 'landing_twitter-en-GB.html';
            }, 300);
        });

        $('#hackwise .socials .linkedin').on('click', function(){
            gtag('event', 'Pre Quiz Share', {'event_label': 'LinkedIn','event_category': 'Social Share'});
            setTimeout(function(){
                window.location = 'landing_linkedin-en-GB.html';
            }, 300);
        });

    }

    if($('.thanks-header .socials').length == 1){



    }

    if ($('.scoring-image').length == 1) {
        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var d = url.searchParams.get("score");

        var scored = getParameterByName('score');

        if (scored == 1) {
            $('.scoring-image .left').removeClass('active');
            $('.scoring-image .books').addClass('active');
            $('.bottom .left .left').removeClass('active');
            $('.bottom .left .books').addClass('active');
            if ($('.thanks-header .bottom .right .infoTech').length == 1) {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl1-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl1-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl1-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            } else {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl1-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl1-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl1-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l1-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            }

            $('meta.social').attr('content', 'http://hm.proofpoint.com/images/level-1-books-social.jpg');

        } else if (scored == 2) {
            $('.scoring-image .left').removeClass('active');
            $('.scoring-image .hat').addClass('active');
            $('.bottom .left .left').removeClass('active');
            $('.bottom .left .hat').addClass('active');
            if ($('.thanks-header .bottom .right .infoTech').length == 1) {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl2-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl2-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl2-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            } else {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl2-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl2-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl2-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l2-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            }
            $('meta.social').attr('content', 'http://hm.proofpoint.com/images/level-2-hat-social.jpg');

        } else if (scored == 3) {
            $('.scoring-image .left').removeClass('active');
            $('.scoring-image .apple').addClass('active');
            $('.bottom .left .left').removeClass('active');
            $('.bottom .left .apple').addClass('active');
            if ($('.thanks-header .bottom .right .infoTech').length == 1) {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl3-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl3-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl3-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            } else {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl3-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl3-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl3-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l3-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            }
            $('meta.social').attr('content', 'http://hm.proofpoint.com/images/level-3-apple-social.jpg');

        } else if (scored == 4) {
            $('.scoring-image .left').removeClass('active');
            $('.scoring-image .satchel').addClass('active');
            $('.bottom .left .left').removeClass('active');
            $('.bottom .left .satchel').addClass('active');
            if ($('.thanks-header .bottom .right .infoTech').length == 1) {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl4-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl4-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl4-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            } else {
                $('.bottom .right .socials .facebook').attr('href', 'https://www.facebook.com/sharer.php?u=http%3A%2F%2Fhm.proofpoint.com%2Flanding_facebook_lvl4-en-GB.html%3Futm_source%3Dfacebook%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-fb%23hackwise');
                $('.bottom .right .socials .twitter').attr('href', 'https://twitter.com/intent/tweet?url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_twitter_lvl4-en-GB.html%3Futm_source%3Dtwitter%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-tw%23hackwise&text=Take%20the%20test%20and%20try%20and%20beat%20my%20score!');
                $('.bottom .right .socials .linkedin').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fhm.proofpoint.com%2Flanding_linkedin_lvl4-en-GB.html%3Futm_source%3Dlinkedin%26utm_medium%3Dorganic-social%26utm_campaign%3Dhackable-me%26utm_content%3Dhwt-user-l4-li%23hackwise&title=Take%20the%20test%20and%20try%20and%20beat%20my%20score!%20&summary=Hackable%20Me%20is%20a%20podcast%20series%20that%20looks%20at%20the%20industry%20of%20hacking%20and%20cybersecurity%2C%20and%20gives%20you%20practical%20tips%20on%20how%20to%20become%20hack-wise.');
            }
            $('meta.social').attr('content', 'http://hm.proofpoint.com/images/level-4-satchel-social.jpg');

        }



    } else {
        // DO NOTHING
    }



    answerOverlay();

    $(window).resize(function(){
//        var slickslistHeight = $('.slick-list').height();
//        $('.slick-slide').height(slickslistHeight+200);

        answerOverlay();
    })

    $('.insightsOpen').on('click', function(e) {
        $(this).parent().toggleClass('active');
//        $('main').toggleClass('active');
        if ($(this).parent().hasClass('active')) {
            answerOverlay();
        } else {
//           $('.insightsOverlay').height(60);
        }
    });
    $('.slider form.slide1:nth-child(2) .button.next').on('click', function(e) {
         var q2answer = $('.question_two input:checked');
         e.preventDefault();

         if (q2answer.length >= 1) {

             var answerInputId2 = $(".answers label").find("input:checked")[1].id;
            $(".question_two .fieldset:nth-child(5) label span").css('color', 'green');

            switch (answerInputId2) {
                case "q2_answer_one":
                    $(".question_two .fieldset:nth-child(2) label span").css('color', 'red');
                    break;
                case "q2_answer_two":
                    $(".question_two .fieldset:nth-child(3) label span").css('color', 'red');
                    break;
                case "q2_answer_three":
                    $(".question_two .fieldset:nth-child(4) label span").css('color', 'red');
                    break;
                default:
                    break;
            }

            $(this).parent().parent().parent().children('.insightsOverlay').toggleClass('active');
            $(this).parent().toggleClass('active');
            $(this).parent().parent().toggleClass('active');

            var insight = $(this).parent().parent().parent().children('.insightsOverlay').offset().top;

         } else {
            $('.question_two input').addClass('error');
            $('.question_two p.error').addClass('active');
         }
         $("html, body").animate({ scrollTop: (insight-200) }, "slow");

         $('.mktoForm input').each(function(i,n){
             $(n).attr('tabindex', (i + 1));
         });
    });

    $('.slider form.slide1:nth-child(3) .button.next').on('click', function(e) {
         var q3answer = $('.question_three input:checked');
         e.preventDefault();

         if (q3answer.length >= 1) {
            var answerInputId3 = $(".answers label").find("input:checked")[2].id;
            $(".question_three .fieldset:nth-child(4) label span").css('color', 'green');

            switch (answerInputId3) {
                case "q3_answer_one":
                    $(".question_three .fieldset:nth-child(2) label span").css('color', 'red');
                    break;
                case "q3_answer_two":
                    $(".question_three .fieldset:nth-child(3) label span").css('color', 'red');
                    break;
                case "q3_answer_four":
                    $(".question_three .fieldset:nth-child(5) label span").css('color', 'red');
                    break;
                default:
                    break;
            }

            $(this).parent().parent().parent().children('.insightsOverlay').toggleClass('active');
            $(this).parent().toggleClass('active');
            $(this).parent().parent().toggleClass('active');

            var insightTwo = $(this).parent().parent().parent().children('.insightsOverlay').offset().top;

            var winHeight = $(this).parent().parent().parent().parent('section.slide1').height();
             var headerHeight = $(this).parent().parent().parent().parent().children('.headerQuestions').height();
             var overlayHeight = winHeight - headerHeight;
             if ($('.insightsOverlay').hasClass('active')) {
//                 $('.insightsOverlay.active').height(overlayHeight);
             } else {
//                $('.insightsOverlay').height(60);
             }

         } else {
            $('.question_three input').addClass('error');
            $('.question_three p.error').addClass('active');
         }
         $("html, body").animate({ scrollTop: (insightTwo-200) }, "slow");

         $('.mktoForm input').each(function(i,n){
             $(n).attr('tabindex', (i + 1));
         });
    });

    $('.slider form.slide1:nth-child(4) .button.next').on('click', function(e) {
         var q4answer = $('.question_four input:checked');
         e.preventDefault();

         if (q4answer.length >= 1) {

            var answerInputId4 = $(".answers label").find("input:checked")[3].id;
            console.log(answerInputId4);
            $(".question_four .fieldset:nth-child(4) label span").css('color', 'green');

            switch (answerInputId4) {
                case "q4_answer_one":
                    $(".question_four .fieldset:nth-child(2) label span").css('color', 'red');
                    break;
                case "q4_answer_two":
                    $(".question_four .fieldset:nth-child(3) label span").css('color', 'red');
                    break;
                case "q4_answer_four":
                    $(".question_four .fieldset:nth-child(5) label span").css('color', 'red');
                    break;
                case "q4_answer_five":
                    $(".question_four .fieldset:nth-child(6) label span").css('color', 'red');
                    break;
                default:
                    break;
            }

            $(this).parent().parent().parent().children('.insightsOverlay').toggleClass('active');
             $(this).parent().toggleClass('active');
             $(this).parent().parent().toggleClass('active');

            var insightThree = $(this).parent().parent().parent().children('.insightsOverlay').offset().top;

             var winHeight = $(this).parent().parent().parent().parent('section.slide1').height();
             var headerHeight = $(this).parent().parent().parent().parent().children('.headerQuestions').height();
             var overlayHeight = winHeight - headerHeight;
             if ($('.insightsOverlay').hasClass('active')) {
//                 $('.insightsOverlay.active').height(overlayHeight);
             } else {
//                $('.insightsOverlay').height(60);
             }

         } else {
            $('.question_four input').addClass('error');
            $('.question_four p.error').addClass('active');
         }
         $("html, body").animate({ scrollTop: (insightThree-200)}, "slow");

         $('.mktoForm input').each(function(i,n){
             $(n).attr('tabindex', (i + 1));
         });
    });

    $('.slider form.slide1:nth-child(5) .button.next').on('click', function(e) {
         var q5answer = $('.question_five input:checked');
         e.preventDefault();

         if (q5answer.length >= 1) {

            var answerInputId5 = $(".answers label").find("input:checked")[4].id;
            console.log(answerInputId5);
            $(".question_five .fieldset:nth-child(4) label span").css('color', 'green');

            switch (answerInputId5) {
                case "q5_answer_one":
                    $(".question_five .fieldset:nth-child(2) label span").css('color', 'red');
                    break;
                case "q5_answer_two":
                    $(".question_five .fieldset:nth-child(3) label span").css('color', 'red');
                    break;
                default:
                    break;
            }


             $(this).parent().parent().parent().children('.insightsOverlay').toggleClass('active');
             $(this).parent().toggleClass('active');
             $(this).parent().parent().toggleClass('active');

             var insightFour = $(this).parent().parent().parent().children('.insightsOverlay').offset().top;

             var winHeight = $(this).parent().parent().parent().parent('section.slide1').height();
             var headerHeight = $(this).parent().parent().parent().parent().children('.headerQuestions').height();
             var overlayHeight = winHeight - headerHeight;



             if ($('.insightsOverlay').hasClass('active')) {

//                 $('.insightsOverlay.active').height(overlayHeight);
             } else {
//                $('.insightsOverlay').height(60);

             }

         } else {
            $('.question_five input').addClass('error');
            $('.question_five p.error').addClass('active');
         }
         $("html, body").animate({ scrollTop: (insightFour-200) }, "slow");

         $('.mktoForm input').each(function(i,n){
             $(n).attr('tabindex', (i + 1));
         });
    });


    //INTRO COMMANDS


    var cxoChoice = $('#cxoChoice').is(':checked');
    var itdmChoice = $('#itdmChoice').is(':checked');
    var firstname = $('#firstname').val();
    var surname = $('#surname').val();

    $('.intro .buttonWrap .button.next').on('click', function(e) {
        var introQuestion = $('.intro_question input:checked');
        e.preventDefault();

        if (introQuestion.length >= 1) {
            $('.slider').slick('slickGoTo', 1);
            $(this).parent().toggleClass('active');
            $(this).parent().height(60);
         } else {
            $('.intro_question input').addClass('error');
            $('.intro_question p.error').addClass('active');
         }
         $("html, body").animate({ scrollTop: 0 }, "slow");

         $('.mktoForm input').each(function(i,n){
             $(n).attr('tabindex', (i + 1));
         });
    });


    // SLIDER CXO COMMANDS
    // NEXT
    $('.slider form.slide1:nth-child(2) .insightNext').on('click', function(e) {
        var q2answer = $('.question_two input:checked');
        e.preventDefault();

        if (q2answer.length >= 1) {
           $('.slider').slick('slickGoTo', 2);
           $(this).parent().toggleClass('active');
           $(this).parent().height(60);
        } else {
           $('.question_two input').addClass('error');
           $('.question_two p.error').addClass('active');
        }
        $("html, body").animate({ scrollTop: 0 }, "slow");

        $('.mktoForm input').each(function(i,n){
            $(n).attr('tabindex', (i + 1));
        });
    });
    $('.slider form.slide1:nth-child(3) .insightNext').on('click', function(e) {
        var q3answer = $('.question_three input:checked');
        e.preventDefault();

        if (q3answer.length >= 1) {
           $('.slider').slick('slickGoTo', 3);
           $(this).parent().toggleClass('active');
            $(this).parent().height(60);
        } else {
           $('.question_three input').addClass('error');
           $('.question_three p.error').addClass('active');
        }
        $("html, body").animate({ scrollTop: 0 }, "slow");

        $('.mktoForm input').each(function(i,n){
            $(n).attr('tabindex', (i + 1));
        });


    });
    $('.slider form.slide1:nth-child(4) .insightNext').on('click', function(e) {
        var q4answer = $('.question_four input:checked');
        e.preventDefault();

        if (q4answer.length >= 1) {
           $('.slider').slick('slickGoTo', 4);
           $(this).parent().toggleClass('active');
            $(this).parent().height(60);
        } else {
           $('.question_four input').addClass('error');
           $('.question_four p.error').addClass('active');
        }
        $("html, body").animate({ scrollTop: 0 }, "slow");

        $('.mktoForm input').each(function(i,n){
            $(n).attr('tabindex', (i + 1));
        });


    });
    $('.slider form.slide1:nth-child(5) .insightNext').on('click', function(e) {
        var q5answer = $('.question_five input:checked');
        var htmlExtension = $('input[name="results"]').val();
        var score = $('input.score-1:checked').length;
        e.preventDefault();

        if (q5answer.length >= 1) {
            // redirect after completion
            if($('.infoTech:checked').length >= 1){
               window.location.href = 'gate_IT-en-GB.html?answers=Hackable Me' + htmlExtension + '&utm_source=' + source + '&utm_medium=' + utmmedium + '&utm_campaign=' + utmcampaign + '&utm_content=' + utmcontent + '&score=' + score;
            } else {
               window.location.href = 'gate_nonIT-en-GB.html?answers=Hackable Me' + htmlExtension + '&utm_source=' + source + '&utm_medium=' + utmmedium + '&utm_campaign=' + utmcampaign + '&utm_content=' + utmcontent + '&score=' + score;
            }

           $(this).parent().toggleClass('active');

        } else {
           $('.question_five input').addClass('error');
           $('.question_five p.error').addClass('active');
        }
        $("html, body").animate({ scrollTop: 0 }, "slow");


        $('.mktoForm input').each(function(i,n){
            $(n).attr('tabindex', (i + 1));
        });

    });

    $('form.slide1 input').each(function(){
        $(this).on('click', function(){

           $('form.slide1,form.slide1 input ').each(function(i,n){
               $(n).attr('tabindex', (i + 1));
           });

           $('.mktoForm input').each(function(i,n){
               $(n).attr('tabindex', (i + 1));
           });
        });

    });

    $('.slider').on('afterChange', function(event, slick, currentSlide){

        console.log('nextfirednow');

        setTimeout(function(){
            $('.mktoForm input').each(function(i,n){
               $(n).attr('tabindex', (i + 1));
            });
        }, 1000);
    });

    $('.slider .form.slide1 .button.prev').on('click', function(e) {
        e.preventDefault();
        $('.slider').slick('slickGoTo', 4);
    });

    //INSIGHTS

    $('input[name=intro_question]').on('change', function(){
        var max = 1;
        var inputChecked = $('input[name=intro_question]:checked').length == max;
        if ($('input[name=intro_question]:checked').length == max) {
            $('input[name=intro_question]').attr('disabled', 'disabled');
            $('input[name=intro_question]:checked').removeAttr('disabled');
        } else {
           $('input[name=intro_question]').removeAttr('disabled');
        }
        var inputId = $('input[name=intro_question]').attr('id');

        if ($('input#q1_answer_one').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').addClass('active');

        } else if ($('input#q1_answer_one').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').removeClass('active');
        }
       if ($('input#q1_answer_two').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').addClass('active');

       } else if ($('input#q1_answer_two').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').removeClass('active');
       }
       if ($('input#q1_answer_three').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').addClass('active');

       } else if ($('input#q1_answer_three').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').removeClass('active');
       }
       if ($('input#q1_answer_four').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').addClass('active');

       } else if ($('input#q1_answer_four').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').removeClass('active');
       }
       if ($('input#q1_answer_five').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').addClass('active');

       } else if ($('input#q1_answer_five').not(':checked')) {
 $(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').removeClass('active');
       }
    });

    $('input[name=question_two]').on('change', function(){
        var max = 1;
        var inputChecked = $('input[name=question_two]:checked').length == max;
        if ($('input[name=question_two]:checked').length == max) {
            $('input[name=question_two]').attr('disabled', 'disabled');
            $('input[name=question_two]:checked').removeAttr('disabled');
        } else {
           $('input[name=question_two]').removeAttr('disabled');
        }
        if ($('input#q2_answer_one').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').addClass('active');

        } else if ($('input#q2_answer_one').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').removeClass('active');
        }
       if ($('input#q2_answer_two').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').addClass('active');

       } else if ($('input#q2_answer_two').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').removeClass('active');
       }
       if ($('input#q2_answer_three').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').addClass('active');

       } else if ($('input#q2_answer_three').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').removeClass('active');
       }
       if ($('input#q2_answer_four').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').addClass('active');

       } else if ($('input#q2_answer_four').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').removeClass('active');
       }
       if ($('input#q2_answer_five').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').addClass('active');

       } else if ($('input#q2_answer_five').not(':checked')) {
 $(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').removeClass('active');
       }
    });

    $('input[name=question_three]').on('change', function(){
        var max = 1;
        var inputChecked = $('input[name=question_three]:checked').length == max;
        if ($('input[name=question_three]:checked').length == max) {
            $('input[name=question_three]').attr('disabled', 'disabled');
            $('input[name=question_three]:checked').removeAttr('disabled');
        } else {
           $('input[name=question_three]').removeAttr('disabled');
        }

        if ($('input#q3_answer_one').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').addClass('active');

        } else if ($('input#q3_answer_one').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').removeClass('active');
        }
       if ($('input#q3_answer_two').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').addClass('active');

       } else if ($('input#q3_answer_two').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').removeClass('active');
       }
       if ($('input#q3_answer_three').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').addClass('active');

       } else if ($('input#q3_answer_three').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').removeClass('active');
       }
       if ($('input#q3_answer_four').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').addClass('active');

       } else if ($('input#q3_answer_four').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').removeClass('active');
       }
       if ($('input#q3_answer_five').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').addClass('active');

       } else if ($('input#q3_answer_five').not(':checked')) {
 $(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').removeClass('active');
       }
    });

    $('input[name=question_four]').on('change', function(){
        var max = 1;
        var inputChecked = $('input[name=question_four]:checked').length == max;
        if ($('input[name=question_four]:checked').length == max) {
            $('input[name=question_four]').attr('disabled', 'disabled');
            $('input[name=question_four]:checked').removeAttr('disabled');
        } else {
           $('input[name=question_four]').removeAttr('disabled');
        }
        if ($('input#q4_answer_one').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').addClass('active');

        } else if ($('input#q4_answer_one').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').removeClass('active');
        }
       if ($('input#q4_answer_two').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').addClass('active');

       } else if ($('input#q4_answer_two').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').removeClass('active');
       }
       if ($('input#q4_answer_three').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').addClass('active');

       } else if ($('input#q4_answer_three').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').removeClass('active');
       }
       if ($('input#q4_answer_four').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').addClass('active');

       } else if ($('input#q4_answer_four').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').removeClass('active');
       }
       if ($('input#q4_answer_five').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').addClass('active');

       } else if ($('input#q4_answer_five').not(':checked')) {
 $(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').removeClass('active');
       }
    });

    $('input[name=question_five]').on('change', function(){
        var max = 1;
        var inputChecked = $('input[name=question_five]:checked').length == max;
        if ($('input[name=question_five]:checked').length == max) {
            $('input[name=question_five]').attr('disabled', 'disabled');
            $('input[name=question_five]:checked').removeAttr('disabled');
        } else {
           $('input[name=question_five]').removeAttr('disabled');
        }

        if ($('input#q5_answer_one').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').addClass('active');

        } else if ($('input#q5_answer_one').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight1').removeClass('active');
        }
       if ($('input#q5_answer_two').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').addClass('active');

       } else if ($('input#q5_answer_two').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight2').removeClass('active');
       }
       if ($('input#q5_answer_three').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').addClass('active');

       } else if ($('input#q5_answer_three').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight3').removeClass('active');
       }
       if ($('input#q5_answer_four').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').addClass('active');

       } else if ($('input#q5_answer_four').not(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight4').removeClass('active');
       }
       if ($('input#q5_answer_five').is(':checked')) {
$(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').addClass('active');

       } else if ($('input#q5_answer_five').not(':checked')) {
 $(this).parent().parent().parent().parent().parent().parent().parent().children().children().children('.insight5').removeClass('active');
       }
    });


    var output = $('.outputBlocks').length;

    if (output == 1) {

        //CXO

        if (window.location.href.indexOf('question_one_cxo_option_1') > 0) {
            $('.question_one_cxo_option_1').addClass('active');
            $('.question_one_cxo_option_1').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_one_cxo_option_2') > 0) {
            $('.question_one_cxo_option_2').addClass('active');
            $('.question_one_cxo_option_2').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_one_cxo_option_3') > 0) {
            $('.question_one_cxo_option_3').addClass('active');
            $('.question_one_cxo_option_3').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_one_cxo_option_4') > 0) {
            $('.question_one_cxo_option_4').addClass('active');
            $('.question_one_cxo_option_4').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_one_cxo_option_5') > 0) {
            $('.question_one_cxo_option_5').addClass('active');
            $('.question_one_cxo_option_5').parent().addClass('active');
        }

        if (window.location.href.indexOf('question_two_cxo_option_1') > 0) {
            $('.question_two_cxo_option_1').addClass('active');
            $('.question_two_cxo_option_1').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_two_cxo_option_2') > 0) {
            $('.question_two_cxo_option_2').addClass('active');
            $('.question_two_cxo_option_2').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_two_cxo_option_3') > 0) {
            $('.question_two_cxo_option_3').addClass('active');
            $('.question_two_cxo_option_3').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_two_cxo_option_4') > 0) {
            $('.question_two_cxo_option_4').addClass('active');
            $('.question_two_cxo_option_4').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_two_cxo_option_5') > 0) {
            $('.question_two_cxo_option_5').addClass('active');
            $('.question_two_cxo_option_5').parent().addClass('active');
        }

        if (window.location.href.indexOf('question_three_cxo_option_1') > 0) {
            $('.question_three_cxo_option_1').addClass('active');
            $('.question_three_cxo_option_1').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_three_cxo_option_2') > 0) {
            $('.question_three_cxo_option_2').addClass('active');
            $('.question_three_cxo_option_2').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_three_cxo_option_3') > 0) {
            $('.question_three_cxo_option_3').addClass('active');
            $('.question_three_cxo_option_3').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_three_cxo_option_4') > 0) {
            $('.question_three_cxo_option_4').addClass('active');
            $('.question_three_cxo_option_4').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_three_cxo_option_5') > 0) {
            $('.question_three_cxo_option_5').addClass('active');
            $('.question_three_cxo_option_5').parent().addClass('active');
        }

        if (window.location.href.indexOf('question_four_cxo_option_1') > 0) {
            $('.question_four_cxo_option_1').addClass('active');
            $('.question_four_cxo_option_1').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_four_cxo_option_2') > 0) {
            $('.question_four_cxo_option_2').addClass('active');
            $('.question_four_cxo_option_2').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_four_cxo_option_3') > 0) {
            $('.question_four_cxo_option_3').addClass('active');
            $('.question_four_cxo_option_3').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_four_cxo_option_4') > 0) {
            $('.question_four_cxo_option_4').addClass('active');
            $('.question_four_cxo_option_4').parent().addClass('active');
        }
        if (window.location.href.indexOf('question_four_cxo_option_5') > 0) {
            $('.question_four_cxo_option_5').addClass('active');
            $('.question_four_cxo_option_5').parent().addClass('active');
        }


        var button = $('.thankyou .headerQuestions .title-question-block .button');

        button.on('click', function(e){

            e.preventDefault();
            const queryString = window.location.href.split('?')[1];

            var forwardURL = 'http://hitachi-csp.enigma.tech/report?' + queryString;
            $(location).attr('href',forwardURL);

        });



    }
    setTimeout(function(){
        var radios = $('.mktoRadioList').length;

        $('.mktoForm input').each(function(i,n){
            $(n).attr('tabindex', (i + 1));
        });

        if ( radios == 1 ) {
            console.log('radios found');
            $('.mktoRadioList').parent().parent().parent().addClass('radioButtons');
        }
    },1500);


});



/*
    ----- MARKETO -----
*/

var marketoFresh = require('./MarketoFresh');

function prepareForm (form) {

     // Set labels as placeholders
     var formElement = document.getElementById('mktoForm_' + form.getId());
     var formFields = formElement.querySelectorAll('div.mktoFieldDescriptor');

     for (var i = 0; i < formFields.length; i++) {
         var label = formFields[i].querySelector('label');
         var input = formFields[i].querySelector('input');
         var select = formFields[i].querySelector('select');

         if (label) {
             if (input) {
                 switch (input.type) {
                     case 'text':
                     case 'email':
                     case 'tel':
                         input.placeholder = label.textContent;

                         if (input.classList.contains('mktoRequired')) {
                             input.placeholder += "*";
                         }


                         formFields[i].removeChild(label);
                     break;
                 }
             }

             if (select) {
                 select.options[0].innerHTML = label.textContent;

                 if (select.classList.contains('mktoRequired')) {
                     select.options[0].innerHTML += "*";
                 }

                 formFields[i].removeChild(label);
             }
         }
     }

    // HDS supplied stuff
    window.hds();

    // Handle form submit
    form.onSubmit(function() {
        // Store email in local storage
        var formData = form.vals();

        storeEmail(formData.Email);

        // Trigger active campaign tracking (inline in /partials/block/asset-form.dust)
        window.formComplete(form);
    });
}

// Setup Marketo form
//if (typeof MarketoSettings !== 'undefined') {
//    MktoForms2.loadForm(
//        MarketoSettings.baseUrl,
//        MarketoSettings.munchkinId,
//        MarketoSettings.formId,
//        marketoFresh(prepareForm)
//    );
//}
















},{"./MarketoFresh":1}]},{},[2]);
